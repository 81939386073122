<template lang="pug">
.wrapper
  .sidebar
    .header
      img(:src="require('@/assets/logo.png')")
      app-dropdown(v-model="open")
        .avatar-container(@click="open = !open")
          span.user-name {{ name }}
          app-avatar
        template(#content)
          .dropdown-menu
            router-link.dropdown-menu__item(:to="{ name: 'Profile' }") {{ $t("userOptions.profile") }}
            router-link.dropdown-menu__item(:to="{ name: 'ChangePass' }") {{ $t("userOptions.changePass") }}
            .dropdown-menu__item(@click="logout") {{ $t("userOptions.logout") }}
    .menu
      router-link(
        v-for="(item, index) in menuItems"
        :to="{ name: item.route }"
        :key="index"
        v-slot="{ isExactActive, navigate }"
        custom
      )
        app-chip(
          :icon="item.icon"
          :label="$t(item.label)"
          :active="isExactActive"
          @click="navigate"
        )
      router-link(:to="{ name: 'Profile' }" v-slot="{ isExactActive, navigate}" custom)
        app-chip(icon="uil uil-user" :label="$t('userOptions.profile')" :active="isExactActive" @click="navigate")
      router-link(:to="{ name: 'ChangePass' }" v-slot="{ isExactActive, navigate}" custom)
        app-chip(icon="uil uil-asterisk" :label="$t('userOptions.changePass')" :active="isExactActive" @click="navigate")
      app-chip(icon="uil uil-signout" :label="$t('userOptions.logout')" @click="logout")
  .content(:class="{ loading }")
    app-loading(v-show="loading")
    .h-full(v-show="!loading")
      slot
</template>

<script>
import { ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import AppAvatar from "@/components/AppAvatar";
import AppChip from "@/components/AppChip";
import AppDropdown from "@/components/AppDropdown";
import AppLoading from "@/components/AppLoading";

import { store } from "@/store";
import { clearToken } from "@/utils/axios";
import { capitalize } from "@/utils/formatters";

export default {
  components: { AppAvatar, AppChip, AppDropdown, AppLoading },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const open = ref(false);

    const name = computed(() => {
      if (store.user?.name) {
        return capitalize(store.user.name).split(" ")[0];
      }

      return "";
    });

    const menuItems = ref([
      {
        label: "menu.home",
        icon: "uil uil-home",
        route: "Home",
        active: true,
      },
      {
        label: "menu.support",
        icon: "uil uil-question-circle",
        route: "SupportIndex",
        active: true,
      },
    ]);

    menuItems.value = menuItems.value.filter((item) => item.active);

    const loading = computed(() => {
      return store.APP_LOADING;
    });

    const logout = () => {
      clearToken();
      router.push({ name: "Login" });
    };

    return { menuItems, route, loading, logout, open, name };
  },
};
</script>

<style lang="scss" scoped>
.header,
.menu,
.content {
  @apply px-5;
}

.menu::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.header {
  @apply py-6;
  @apply flex;
  @apply justify-between;
  @apply items-center;
  @apply transition-all;
  img {
    @apply h-9;
  }

  @screen md {
    @apply bg-white;
    @apply mb-6;
    @apply flex-col;
    @apply items-start;
    img {
      @apply h-14;
      @apply m-auto;
    }
  }
}

.wrapper {
  @apply h-full;
  @apply flex;
  @apply flex-col;
  @screen md {
    @apply flex-row;
  }
}

.sidebar {
  @screen md {
    @apply shadow-bluegray-900-lg;
    @apply w-72;
    @apply bg-gignetgreen-500;
    ::v-deep .chip {
      @apply bg-transparent;
      @apply text-white;
      @apply font-medium;
      i {
        @apply mr-4;
      }
      &:focus {
        @apply bg-transparent;
        @apply text-white;
      }
      &:hover,
      &.active {
        @apply bg-white;
        @apply text-gignetgreen-500;
      }
    }
  }
}

.menu {
  @apply overflow-x-scroll;
  @apply w-full;
  @apply grid;
  @apply grid-flow-col;
  @apply auto-cols-max;
  @apply gap-4;
  @apply my-4;
  @screen md {
    @apply overflow-auto;
    @apply grid-flow-row;
    @apply auto-cols-fr;
    @apply gap-6;
  }
}

.content {
  @apply flex-1;
  @apply overflow-auto;
  @screen md {
    @apply py-4;
  }
}

.dropdown-menu {
  @apply flex;
  @apply items-center;
  &__item {
    @apply px-3;
    @apply py-4;
    @apply text-bluegray-600;
    @apply text-center;
    @apply flex-1;
    &:hover {
      @apply bg-bluegray-50;
    }
  }
  @screen md {
    @apply text-sm;
  }
}

.welcome {
  @apply flex-1;
  @apply text-xl;
  @apply font-semibold;
  word-spacing: 9999999px;
}

.avatar-container {
  @apply cursor-pointer;
  @apply flex;
  @apply items-center;
  @apply text-sm;
  @screen md {
    @apply hidden;
  }
  span {
    @apply capitalize;
    @apply text-bluegray-500;
    @apply font-medium;
    @apply px-2;
    @apply pt-1;
  }
}
</style>
